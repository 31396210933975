const { createContext, useContext } = require('react')

const UserContext = createContext()

export default UserContext
export const checkPermission = (cName) => {
  const { userState } = useContext(UserContext)
  const { permissions, userType } = userState

  return userType === 'admin'
    ? { view: true, add: true, edit: true, delete: true }
    : permissions.find((e) => e.name === cName)
}
